
.App {
  text-align: center;
    background-color: #282c34;
    color:white !important

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.setLabel{
  cursor: pointer;
}

.setLabel:hover{
  opacity:0.8
}

.xout:hover{
  opacity:0.5;
  cursor: pointer;
  transition: 0.5s opacity ease-in-out;
}

.gear {
    display: none;
}

@media screen and (max-width: 575px) {
    .navContainer {
        position: absolute;
        height: 80vh;
        top: 20vh;
        overflow: scroll;
        z-index: 1000000;
        width: 100%;
        border-radius: 20px;
        min-width: 0px !important;
        transition: all 1s ease-in-out;
        opacity: 1;
        visibility: unset;
    }

    .navContainer {
        background-color: #05092e !important;
        padding-top: 14px;
        border:1px solid white;
    }

    .navContainerClosed {
        transition: opacity 1s ease-in-out;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .gear{
        display: block !important;
        z-index: 100000000;
    }
    .navContainer-mobile {
    }
}

.tabElement:hover {
    color:white !important;
}

.uploadBox:hover {
    color:white !important;
    border-color: white !important;
}




/*    :root {*/
/*        --background-color: #1F2227;*/
/*        --text-color: white;*/
/*        --hilight-color: #8db4d6;*/
/*    }*/

/*    !* Some data colors. Light mode uses defaults *!*/
/*    .highcharts-color-0 {*/
/*        fill: #0460ba;*/
/*        stroke: #0460ba;*/
/*    }*/
/*    .highcharts-color-1 {*/
/*        fill: #9696ab;*/
/*        stroke: #9696ab;*/
/*    }*/


/*body {*/
/*    background-color: var(--background-color);*/
/*}*/
/*.highcharts-background {*/
/*    fill: var(--background-color);*/
/*}*/
/*.highcharts-container text {*/
/*    fill: var(--text-color);*/
/*}*/
/*.highcharts-subtitle,*/
/*.highcharts-credits,*/
/*.highcharts-axis-title {*/
/*    fill-opacity: 0.7;*/
/*}*/
/*.highcharts-grid-line {*/
/*    stroke: var(--text-color);*/
/*    stroke-opacity: 0.2;*/
/*}*/
/*.highcharts-tooltip-box {*/
/*    fill: var(--background-color);*/
/*}*/
/*.highcharts-column-series rect.highcharts-point {*/
/*    stroke: var(--background-color);*/
/*}*/
